/* eslint-disable */
import { Box, Button, Divider, IconButton, TextField, Tooltip } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  SiteByIdDTO,
  UserDTO,
  deActivateEstimateById,
  deleteCollectorById,
  deleteSiteById,
  getSiteById,
  getUnitCostByCollectorId,
  updateCollector,
  updateSite,
} from "api";

import {
  CheckIcon,
  CrossIcon,
  EsgRecIcon,
  EsgRecGreenIcon,
  EsgEditIcon,
  InfoIcon,
  PlusIcon,
  EsgDeleteIcon,
  TrashIcon,
} from "assets";

import { COLORS, DeleteDialog, EMPTY_CELL_VALUE, H4, H5Bold, useDialogControls } from "components";

import {
  useDeActivatedCollectorsBySiteId,
  useDeActivatedEstimatesBySiteId,
  useNotify,
} from "hooks";

import { QUERY_KEYS } from "consts";

import { SITES_PAGE } from "pages/authenticated/sites/consts";

import { useUserRole } from "recoils";

import { getDateFormat, toUSNumber, checkNullValue, isNullOrUndefined, getUTCDateFormat } from "utils";

import { CollectorModal } from "./components/CollectorModal";
import CustomSnackbar from "./components/CustomSnackBar";
import { EstimatesModal } from "./components/EstimatesModal";
import UnitCostInfoDrawer from "./components/UnitCostInfoDrawer";
import { UserModal } from "./components/UserModal";
import { AllowedFormValues } from "./types";

import { SiteNameDetail } from "../site-delete-dialog-name";

export const SiteEditorForm: FunctionComponent<{
  allowedUsers: UserDTO[];
  allowedValues: AllowedFormValues;
  site?: SiteByIdDTO;
}> = ({ allowedValues, site, allowedUsers }) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const userRole = useUserRole();
  const { isAdmin } = useUserRole();
  const navigate = useNavigate();

  const [addCollectorModalOpen, setAddCollectorModalOpen] = useState(false);
  const [addEstimatesModalOpen, setAddEstimatesModalOpen] = useState(false);
  const [EditCollector, setEditCollector] = useState<any | undefined>();
  const [EditCollectorOpen, setEditCollectorOpen] = useState(false);
  const [EditEstimate, setEditEstimate] = useState<any | undefined>();
  const [EditEstimateOpen, setEditEstimateOpen] = useState(false);
  const [EstimateData, setEstimateData] = useState<any | undefined>();
  const [collectorData, setCollectorData] = useState<any | undefined>();
  const [UnitCostData, setUnitCostData] = useState<any | undefined>();
  const [toDeleteSite, setDeleteSite] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [deleteCollectorId, setDeleteCollectorId] = useState<
    number | undefined
  >(undefined);

  const [deleteEstimateId, setDeleteEstimateId] = useState<number | undefined>(
    undefined,
  );

  const [isEstimateModalOpen, setEstimateModalOpen] = useState(false);
  const [isEditNotes, setIsEditNotes] = useState(false);

  const [isCollectorModalOpen, setCollectorModalOpen] = useState(false);
  const [isCollectorEditNotes, setIsCollectorEditNotes] = useState(false);

  const [isEditUnitCost, setIsEditUnitCost] = useState(false);
  const [showSideInfoDrawer, setShowSideInfoDrawer] = useState(false);
  const [historicData, setHistoricData] = useState<any>(null);

  const deleteDialogControls = useDialogControls();
  const { mutateAsync: updateSiteRequest } = useMutation(updateSite);
  const { mutateAsync: deleteSiteRequest } = useMutation(deleteSiteById);

  const [siteData, setSiteData] = useState<SiteByIdDTO | undefined>(site);
  const [openSnackBar, setOpenSnackBar] = useState(false);


  useEffect(() => {
    setSiteData(site);
  }, [site])

  const onCollectorDataUpdate = (data: any) => {
    setSiteData(data);
  };

  const onEstimatesDataUpdate = (data: any) => {
    setSiteData(data);
  };

  const { mutateAsync: updateCollectorRequest } = useMutation(updateCollector);

  useEffect(() => {
    if (siteData) window.localStorage.setItem("Site", JSON.stringify(siteData));
  }, [siteData]);

  useEffect(() => {
    const fetchData = async () => {
      if (UnitCostData) {
        try {
          const data = await getUnitCostByCollectorId(UnitCostData?.id);
          setHistoricData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [UnitCostData]);

  const { mutateAsync: deleteCollectorRequest } =
    useMutation(deleteCollectorById);

  const { mutateAsync: deleteEstimateRequest } = useMutation(
    deActivateEstimateById,
  );

  let deActivatedCollectorsList: any = [];

  let deActivatedEstimatesList: any = [];

  if (siteData?.id) {
    const { data: DeActivatedcollectorsFromApi } =
      // eslint-disable-next-line
      useDeActivatedCollectorsBySiteId(siteData!.id);
    if (DeActivatedcollectorsFromApi)
      deActivatedCollectorsList = DeActivatedcollectorsFromApi;
    const { data: DeActivatedEstimatesFromApi } =
      // eslint-disable-next-line
      useDeActivatedEstimatesBySiteId(siteData!.id);
    if (DeActivatedEstimatesFromApi)
      deActivatedEstimatesList = DeActivatedEstimatesFromApi;
  }

  const handleEditCollector = (rowData: any) => {
    setEditCollectorOpen(true);
    setEditCollector(rowData);
  };

  const handleEditEstimates = (rowData: any) => {
    setEditEstimateOpen(true);
    setEditEstimate(rowData);
  };

  const handleCloseCollectorModal = () => {
    const isAddCollectorModalOpen = addCollectorModalOpen;
    const isEditCollectorOpen = EditCollectorOpen;

    if (isAddCollectorModalOpen) {
      setAddCollectorModalOpen(false);
    }
    if (isEditCollectorOpen) {
      setEditCollectorOpen(false);
    }
    if (isCollectorEditNotes) {
      setCollectorModalOpen(false);
      setIsCollectorEditNotes(false);
    }

    setEditCollector(undefined);
  };

  const handleCloseEstimatesModal = () => {
    const isAddEstiamtesModalOpen = addEstimatesModalOpen;
    const isEditEstimateOpen = EditEstimateOpen;

    if (isAddEstiamtesModalOpen) {
      setAddEstimatesModalOpen(false);
    }
    if (isEditEstimateOpen) {
      setEditEstimateOpen(false);
    }
    if (isEstimateModalOpen) {
      setEstimateModalOpen(false);
      setIsEditNotes(false);
    }

    setEditEstimate(undefined);
  };

  const setCollectorToDelete = (id: number | undefined) => {
    deleteDialogControls.open();
    if (id) {
      setDeleteCollectorId(id);
    }
  };

  const setEstimateToDelete = (id: number | undefined) => {
    deleteDialogControls.open();
    if (id) {
      setDeleteEstimateId(id);
    }
  };

  const deleteCollector = (id: number | undefined) => {
    onDisableCollector(id);
    deleteDialogControls.close();
  };

  const deleteEstimate = (id: number | undefined) => {
    onDisableEstimate(id);
    deleteDialogControls.close();
  };

  const handleDeleteProvider = async (deleteUserId: number | undefined) => {
    if (siteData && deleteUserId) {
      const selectedDataProviderId = deleteUserId;
      const existingUserIds = siteData.users.map((user) => user.id);
      const updatedDataProviders = existingUserIds.filter(
        (userId) => userId !== selectedDataProviderId,
      );

      await updateSiteRequest(
        {
          ...siteData,
          dataProviders: updatedDataProviders,
        },
        {
          onError: () => {
            notify.error(
              "Some error has occurred while deleting the data provider!",
            );
          },
          onSuccess: () => {
            notify.success("Data provider successfully deleted!");
            queryClient.invalidateQueries([QUERY_KEYS.SITES]);
          },
        },
      );
    }
  };

  const onDisableCollector = async (collectorId: number | undefined) => {
    if (siteData?.id && collectorId) {
      await deleteCollectorRequest(collectorId, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(`Some error has happen while deleting Collector!`);
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Collector successfully deleted!`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
          queryClient.invalidateQueries([
            QUERY_KEYS.COLLECTORS,
            QUERY_KEYS.SITES,
            site?.id,
          ]);
          if (site) {
            getSiteById(site?.id)
              .then((siteDetails) => {
                setSiteData(siteDetails);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error("Error fetching site data:", error);
              });
          }
        },
      });
    }
  };

  const onDisableEstimate = async (EstimateId: number | undefined) => {
    if (siteData?.id && EstimateId) {
      await deleteEstimateRequest(EstimateId, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(`Some error has happen while de-activating Estimate!`);
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Estimate successfully de-activated!`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
          queryClient.invalidateQueries([
            QUERY_KEYS.ESTIMATES,
            QUERY_KEYS.SITES,
            site?.id,
          ]);
          if (site) {
            getSiteById(site?.id)
              .then((siteDetails) => {
                setSiteData(siteDetails);
              })
              .catch((error) => {
                // eslint-disable-next-line
                console.error("Error fetching site data:", error);
              });
          }
        },
      });
    }
  };

  const onDeleteClick = () => {
    if (siteData?.id) {
      deleteSiteRequest(siteData.id, {
        onError: () => {
          deleteDialogControls.close();
          notify.error(
            `Some error has happen while deleting site: ${siteData.name}!`,
          );
        },
        onSuccess: () => {
          deleteDialogControls.close();
          notify.success(`Site successfully deleted: ${siteData.name}!`);
          queryClient.invalidateQueries([QUERY_KEYS.SITES]);
          navigate(SITES_PAGE.ABSOLUTE_PATH);
        },
      });
    }
  };

  const dataProvidersColumns = useMemo(
    () => [
      {
        accessorKey: "firstName",
        header: "First Name",
      },
      {
        accessorKey: "lastName",
        header: "Last Name",
      },
      {
        accessorKey: "email",
        header: "Email Address",
      },
    ],
    [],
  ) as MRT_ColumnDef<any>[];

  const collectorsColumns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "scope",
      header: "GHG Emission",
      size: 10,
    },
    {
      accessorKey: "utilityTypeName",
      header: "Utility Type",
      size: 10,
    },
    {
      accessorKey: "utilitySubType",
      header: "Utility Subtype",
      size: 10,
    },
    {
      accessorKey: "accountNumber",
      header: "Account Number",
      size: 10,
    },
    {
      accessorKey: "meterNumber",
      header: "Meter Number",
      size: 10,
    },
    {
      accessorKey: "providerName",
      header: "Utility Provider",
      size: 10,
    },
    {
      accessorKey: "allowedDocuments",
      header: "Document Type",
      size: 10,
    },
    {
      accessorKey: "startDate",
      header: "Start Date",
      size: 10,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        if (isNullOrUndefined(value) || value === 0) {
          return EMPTY_CELL_VALUE;
        }
        return dayjs(value as string)
          .utc()
          .format(getUTCDateFormat());
      },
    },
    {
      accessorKey: "endDate",
      header: "End Date",
      size: 10,
      Cell: ({ cell }) => {
        const value = cell.getValue();
        if (isNullOrUndefined(value) || value === 0) {
          return EMPTY_CELL_VALUE;
        }
        return dayjs(value as string)
          .utc()
          .format(getUTCDateFormat());
      },
    },
    {
      accessorKey: "frequency",
      header: "Frequency",
      size: 10,
    },
    {
      accessorKey: "consumptionUnit",
      header: "Consumption Unit",
      size: 10,
    },
    {
      accessorKey: "unitCost",
      header: "Unit Cost",
      size: 10,
      Header: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ fontSize: "14px" }}>Unit Cost</span>

          <Tooltip
            title="This value is computed by dividing your overall bill cost by your complete usage and requires manual input"
            placement="top"
          >
            <IconButton
              aria-label="info"
              size="small"
              sx={{ maxHeight: "23px", maxWidth: "23px" }}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
      Cell: ({ row }) => {
        const [unitCostValue, setUnitCostValue] = useState(
          row.original.unitCost || "",
        );
        const updatedRow = { ...row.original };

        const handleEditUnitCost = () => {
          setIsEditUnitCost(true);
          setShowSideInfoDrawer(true);
          setUnitCostData(row.original);
        };

        const handleSaveUnitCost = async () => {
          if (siteData) {
            const updatedCollectorData: any = {
              accountNumber: checkNullValue(row.original.accountNumber),
              allowedDocuments: Array.isArray(row.original.allowedDocuments)
                ? checkNullValue(row.original.allowedDocuments)
                : [row.original.allowedDocuments],
              consumptionUnit: checkNullValue(row.original.consumptionUnit),
              currency: checkNullValue(row.original.currency),
              frequency: checkNullValue(row.original.frequency),
              meterNumber: checkNullValue(row.original.meterNumber),
              providerName: checkNullValue(row.original.providerName),
              notes: checkNullValue(row.original.notes),
              scope: checkNullValue(row.original.scope),
              unitCost: unitCostValue,
              isSpendBased: checkNullValue(row.original.spendBased),
              siteId: siteData.id,
              id: checkNullValue(row.original.id),
              utilityTypeId: checkNullValue(row.original.utilityTypeId),
            };
            await updateCollectorRequest(updatedCollectorData, {
              onError: () => {
                notify.error(
                  `Some error has happened while editing Collector!`,
                );
              },
              onSuccess: () => {
                // notify.info(`Your Unit Cost has now changed and will now apply to all future bills!`);
                setOpenSnackBar(true);
                setIsEditUnitCost(false);
                setShowSideInfoDrawer(false);
                if (site) {
                  getSiteById(site?.id)
                    .then((siteData) => {
                      setSiteData(siteData);
                    })
                    .catch((error) => {
                      // eslint-disable-next-line
                      console.error("Error fetching site data:", error);
                    });
                }
              },
            });
          }
        };

        const handleCancelEdit = () => {
          setIsEditUnitCost(false);
          setShowSideInfoDrawer(false);
        };

        return (
          <>
            {row.original.spendBased && (
              <>
                {isEditUnitCost && UnitCostData.id === row.original.id ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                    }}
                  >
                    <TextField
                      sx={{ width: "140px" }}
                      variant="outlined"
                      value={unitCostValue}
                      onChange={(e) => setUnitCostValue(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <div style={{ display: "flex" }}>
                            <IconButton
                              aria-label="Save"
                              size="small"
                              sx={{
                                maxHeight: "24px",
                                maxWidth: "24px",
                                color: "green",
                              }}
                              onClick={handleSaveUnitCost}
                            >
                              <CheckIcon />
                            </IconButton>
                            <IconButton
                              aria-label="Cancel"
                              size="small"
                              sx={{
                                maxHeight: "24px",
                                maxWidth: "24px",
                                color: "red",
                              }}
                              onClick={handleCancelEdit}
                            >
                              <CrossIcon />
                            </IconButton>
                          </div>
                        ),
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      maxWidth: row.original.unitCost !== "0" ? "80px" : "20px",
                    }}
                  >
                    {row.original.unitCost === null ||
                      row.original.unitCost === "0" ? (
                      <Tooltip title="Edit Unit Cost">
                        <IconButton
                          aria-label="Edit"
                          size="small"
                          sx={{ maxHeight: "24px", maxWidth: "24px" }}
                          onClick={handleEditUnitCost}
                        >
                          <EsgEditIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <>
                        <Tooltip title={row.original.unitCost}>
                          <Box
                            sx={{
                              alignItems: "center",
                              width: "100px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            <span>{row.original.unitCost}</span>
                          </Box>
                        </Tooltip>
                        {row.original.enabled && (
                          <Button
                            sx={{
                              borderRadius: "4px",
                              height: "20.5px",
                              minWidth: "38px",
                              padding: "1px",
                              fontSize: "12px",
                            }}
                            variant="contained"
                            onClick={handleEditUnitCost}
                          >
                            Edit
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            {openSnackBar && (
              <CustomSnackbar
                open={openSnackBar}
                message="Your Unit Cost has now changed and will now apply to all future bills!"
                onClose={() => setOpenSnackBar(false)}
              />
            )}
          </>
        );
      },
    },
    {
      accessorKey: "currency",
      header: "Currency",
      size: 10,
    },
    {
      accessorKey: "notes",
      header: "Notes",
      Cell: ({ row }) => {
        const handleEditNotesInCollector = () => {
          setCollectorModalOpen(true);
          setIsCollectorEditNotes(true);
          setCollectorData(row.original);
        };

        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                maxWidth: row.original.notes !== null ? "140px" : "20px",
              }}
            >
              {row.original.notes === null || row.original.notes === "" ? (
                <Tooltip title="Edit Notes">
                  <IconButton
                    aria-label="Edit"
                    size="small"
                    sx={{ maxHeight: "24px", maxWidth: "24px" }}
                    onClick={handleEditNotesInCollector}
                  >
                    <EsgEditIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title={row.original.notes}>
                    <Box
                      sx={{
                        alignItems: "center",
                        width: "100px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      <span>{row.original.notes}</span>
                    </Box>
                  </Tooltip>
                  {row.original.enabled && (
                    <Button
                      sx={{
                        borderRadius: "4px",
                        height: "20.5px",
                        minWidth: "38px",
                        padding: "1px",
                        fontSize: "12px",
                      }}
                      variant="contained"
                      onClick={handleEditNotesInCollector}
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const EstimatesColumns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "estimateName",
      header: "Estimate Name",
    },
    {
      accessorKey: "utilityName",
      header: "Utility Type",
    },
    {
      accessorKey: "estimateType",
      header: "Estimate Type",
    },
    {
      accessorKey: "estimateHeadCount",
      header: "Estimation Value",
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;
        return (
          <Tooltip title={value}>
            <Box>{toUSNumber(value)}</Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "estimateUsage",
      header: "Estimated Usage per Day",
      Header: <Box>Estimated Usage per Day</Box>,
      size: 240,
      Cell: ({ cell }: any) => {
        const value = cell.getValue() as string;
        return (
          <Tooltip title={value}>
            <Box>{toUSNumber(value)}</Box>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "estimateUsageUnit",
      header: "Consumption Unit",
    },
    {
      accessorKey: "estimateStartDate",
      header: "Start Date",
      accessorFn: (row) => dayjs(row.estimateStartDate).utc().format(getDateFormat()),
    },
    {
      accessorKey: "estimateEndDate",
      header: "End Date",
      accessorFn: (row) => dayjs(row.estimateEndDate).utc().format(getDateFormat()),
    },
    {
      accessorKey: "scope",
      header: "GHG_Emissions",
    },
    {
      accessorKey: "notes",
      header: "Notes",
      Cell: ({ row }) => {
        const handleEditNotesInEstimates = () => {
          setEstimateModalOpen(true);
          setIsEditNotes(true);
          setEstimateData(row.original);
        };

        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                maxWidth: row.original.notes !== "" ? "140px" : "20px",
              }}
            >
              <Tooltip title={row.original.notes}>
                <Box
                  sx={{
                    alignItems: "center",
                    width: "100px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  <span>{row.original.notes}</span>
                </Box>
              </Tooltip>
              {row.original.enabled && row.original.notes === "" && (
                <IconButton
                  aria-label="Edit"
                  size="small"
                  sx={{ maxHeight: "24px", maxWidth: "24px" }}
                  onClick={handleEditNotesInEstimates}
                >
                  <EsgEditIcon />
                </IconButton>
              )}
              {row.original.enabled && row.original.notes !== "" && (
                <Button
                  sx={{
                    borderRadius: "4px",
                    height: "20.5px",
                    minWidth: "38px",
                    padding: "1px 1px 1px 1px",
                    fontSize: " 12px",
                  }}
                  variant="contained"
                  onClick={handleEditNotesInEstimates}
                >
                  Edit
                </Button>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const collectorRecords = JSON.parse(
    JSON.stringify(siteData?.collectors || []),
  );
  const userRecords = JSON.parse(JSON.stringify(siteData?.users || []));

  const estimatesRecords = JSON.parse(
    JSON.stringify(siteData?.estimates || []),
  );

  const handleREC = (e: any) => {
    const recId = e.recsDetail.recId;
    navigate(`/rec/${recId}`, { state: e.recsDetail });
  };

  return (
    <>
      <H4 mt={10} mb={2}>
        Parameter Based Estimations
      </H4>
      <Divider sx={{ mt: 2, mb: 2, backgroundColor: '#054254' }} />
      <br />
      <Button
        sx={{ my: 2, borderRadius: '8px' }}
        variant="contained"
        onClick={() => setAddEstimatesModalOpen(true)}
        startIcon={<PlusIcon />}
        disabled={userRole.isAuditor || userRole.isDataProvider}
      >
        Add Estimates
      </Button>
      <br />
      <Box>
        <MaterialReactTable
          muiTableContainerProps={{
            sx: {
              border: "1px solid #D9D9D9", borderRadius: '10px',
              minWidth: '98%',
              width: '98%',
              maxWidth: '98%',
              '@media (min-width: 600px)': {
                width: '98%',
              },
              '@media (min-width: 960px)': {
                width: '1100px',
              },
              '@media (min-width: 1280px)': {
                width: '1100px',
              },
              '@media (min-width: 1440px)': {
                width: '1100px',
              },
              '@media (min-width: 1680px)': {
                width: '1300px',
              },
              '@media (min-width: 2400px)': {
                width: '100%',
              },
            },
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '10px',
              boxShadow: 'none'
            }
          }}
          muiTopToolbarProps={{
            sx: {
              display: 'none'
            }
          }}
          muiBottomToolbarProps={{
            sx: {
              display: 'none'
            }
          }}
          muiTableHeadCellProps={{
            sx: {
              lineHeight: '30px',
              '& .MuiBadge-root': {
                display: 'none',
              },
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              height: '45px',
              backgroundColor: '#F8FAFC',
            }
          }}
          muiTableBodyRowProps={{
            sx: {
              height: '45px',
              backgroundColor: 'white',
            }
          }}
          columns={EstimatesColumns}
          data={estimatesRecords.concat(deActivatedEstimatesList)}
          enablePagination={false}
          enableDensityToggle={false}
          initialState={{
            density: "compact",
          }}
          enableRowActions={siteData?.enabled}
          renderRowActions={({ row }) => (
            <div style={{ color: row.original.enabled ? "" : "red", borderRight: '1px solid rgba(224,224,224,1)', height: '45px', alignItems: 'center', display: 'flex', paddingRight: '5px' }}>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                {row.original.enabled ? (
                  <>
                    {/* Edit */}
                    <Tooltip arrow placement="left" title="Edit">
                      <IconButton
                        sx={{ width: "35px", height: "35px" }}
                        onClick={() => handleEditEstimates(row.original)}
                      >
                        <EsgEditIcon />
                      </IconButton>
                    </Tooltip>
                    {/* Delete */}
                    <Tooltip arrow placement="right" title="De-activate">
                      <IconButton
                        sx={{
                          width: "35px",
                          height: "35px",
                          marginLeft: "-15px",
                        }}
                        onClick={() =>
                          setEstimateToDelete(row.original.id as number)
                        }
                      >
                        <EsgDeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <span style={{ color: "red" }}>De-activated</span>
                )}
              </Box>
            </div>
          )}
        />
      </Box>
      <br />
      <H4 mt={10} mb={2}>
        Utility Providers
      </H4>
      <Divider sx={{ mt: 2, mb: 2, backgroundColor: '#054254' }} />
      <br />
      <Button
        sx={{ my: 2, borderRadius: '8px' }}
        variant="contained"
        onClick={() => setAddCollectorModalOpen(true)}
        startIcon={<PlusIcon />}
        disabled={userRole.isAuditor}
      >
        Add Utility Provider
      </Button>
      <br />
      <Box>
        <MaterialReactTable
          muiTableContainerProps={{
            sx: {
              border: "1px solid #D9D9D9", borderRadius: '10px',
              minWidth: '98%',
              width: '98%',
              maxWidth: '98%',
              '@media (min-width: 600px)': {
                width: '98%',
              },
              '@media (min-width: 960px)': {
                width: '1100px',
              },
              '@media (min-width: 1280px)': {
                width: '1100px',
              },
              '@media (min-width: 1440px)': {
                width: '1100px',
              },
              '@media (min-width: 1680px)': {
                width: '1300px',
              },
              '@media (min-width: 2400px)': {
                width: '100%',
              },
            }
          }}
          muiTablePaperProps={{
            sx: {
              borderRadius: '10px',
              boxShadow: 'none'
            }
          }}
          muiTopToolbarProps={{
            sx: {
              display: 'none'
            }
          }}
          muiBottomToolbarProps={{
            sx: {
              display: 'none'
            }
          }}
          muiTableHeadCellProps={{
            sx: {
              lineHeight: '30px',
              '& .MuiBadge-root': {
                display: 'none',
              },
            }
          }}
          muiTableHeadRowProps={{
            sx: {
              height: '45px',
              backgroundColor: '#F8FAFC',
            }
          }}
          muiTableBodyRowProps={{
            sx: {
              height: '45px',
              backgroundColor: 'white',
            }
          }}
          columns={collectorsColumns}
          data={[
            ...collectorRecords,
            ...deActivatedCollectorsList.map((collector: any) => ({
              ...collector,
              isDeactivated: true,
            })),
          ].sort((a, b) => {
            if (a.disableDate === null && b.disableDate !== null) {
              return -1;
            }
            if (a.disableDate !== null && b.disableDate === null) {
              return 1;
            }
            return 0;
          })}
          enablePagination={false}
          enableDensityToggle={false}
          initialState={{
            density: "compact",
          }}

          enableRowActions={siteData?.enabled}
          renderRowActions={({ row }) => (
            <div style={{ color: row.original.isDeactivated ? "red" : "", borderRight: '1px solid rgba(224,224,224,1)', height: '45px', alignItems: 'center', display: 'flex', paddingRight: '5px' }}>
              <Box sx={{ display: "flex" }}>

                {row.original.disableDate === null ? (
                  <>
                    {/* REC Document */}
                    {row.original.recs === true && row.original.recsDetail !== null ? (
                      <Tooltip
                        arrow
                        placement="top"
                        title={
                          row.original.recsDetail.recsUploaded === true
                            ? "REC"
                            : "REC document missing"
                        }
                      >
                        <IconButton
                          sx={{ width: "35px", height: "35px" }}
                          onClick={() => handleREC(row.original)}
                        >
                          {row.original.recsDetail.recsUploaded === true ? (
                            <EsgRecGreenIcon />
                          ) : (
                            <EsgRecIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <IconButton
                        sx={{ width: "35px", height: "35px", display: "block" }}
                      ></IconButton>
                    )}
                    {/* Edit */}
                    <Tooltip arrow placement="top" title="Edit">
                      <IconButton
                        sx={{ width: "35px", height: "35px" }}
                        onClick={() => handleEditCollector(row.original)}
                      >
                        <EsgEditIcon />
                      </IconButton>
                    </Tooltip>
                    {/* Delete */}
                  </>
                ) : (
                  <span style={{ color: "red" }}>De-activated</span>
                )}
              </Box>
            </div>
          )}
        />
      </Box>
      <br />
      <H4 mt={10} mb={2}>
        Data Providers
      </H4>
      <Divider sx={{ mt: 2, mb: 2, backgroundColor: '#054254' }} />
      <br />
      <Button
        sx={{ my: 2, borderRadius: '8px' }}
        variant="contained"
        onClick={() => setAddUserModalOpen(true)}
        startIcon={<PlusIcon />}
        disabled={userRole.isAuditor || userRole.isDataProvider}
      >
        Add Data Provider
      </Button>
      <br />

      <MaterialReactTable
        muiTableContainerProps={{
          sx: {
            border: "1px solid #D9D9D9", borderRadius: '10px',
            minWidth: '98%',
            width: '98%',
            maxWidth: '98%',
            '@media (min-width: 600px)': {
                width: '98%',
              },
              '@media (min-width: 960px)': {
                width: '1100px',
              },
              '@media (min-width: 1280px)': {
                width: '1100px',
              },
              '@media (min-width: 1440px)': {
                width: '1100px',
              },
              '@media (min-width: 1680px)': {
                width: '1300px',
              },
              '@media (min-width: 2400px)': {
                width: '100%',
              },
          },
        }}
        muiTablePaperProps={{
          sx: {
            borderRadius: '10px',
            boxShadow: 'none'
          }
        }}
        muiTopToolbarProps={{
          sx: {
            display: 'none'
          }
        }}
        muiBottomToolbarProps={{
          sx: {
            display: 'none'
          }
        }}
        muiTableHeadCellProps={{
          sx: {
            lineHeight: '30px',
            '& .MuiBadge-root': {
              display: 'none',
            },
          }
        }}
        muiTableHeadRowProps={{
          sx: {
            height: '45px',
            backgroundColor: '#F8FAFC',
          }
        }}
        muiTableBodyRowProps={{
          sx: {
            height: '45px',
            backgroundColor: 'white',
          }
        }}
        columns={dataProvidersColumns}
        data={userRecords}
        enableDensityToggle={false}
        initialState={{
          density: "compact",
        }}
        enableRowActions={siteData?.enabled}
        enablePagination={false}
        renderRowActions={({ row }) => (
          <Box sx={{
            borderRight: '1px solid rgba(224,224,224,1)', height: '45px', alignItems: 'center', display: 'flex', paddingRight: '5px'
          }}>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton
                sx={{ width: "35px", height: "35px" }}
                color="error"
                disabled={userRecords.length === 1}
                onClick={() => handleDeleteProvider(row.original.id as number)}
              >
                <EsgDeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
      <br />
      <div style={{ display: "flex", gap: "16px" }}>
        <Button
          sx={{ minWidth: "150px", backgroundColor: COLORS.Fuzzy_Brown }}
          disabled={!isAdmin}
          onClick={() => {
            deleteDialogControls.open();
            setDeleteSite(true);
          }}
          startIcon={<TrashIcon />}
          variant="contained"
        >
          Delete site
        </Button>
      </div>

      <EstimatesModal
        isOpen={
          addEstimatesModalOpen || EditEstimateOpen || isEstimateModalOpen
        }
        onClose={handleCloseEstimatesModal}
        columns={EstimatesColumns}
        allowedValues={allowedValues}
        site={siteData || ({} as SiteByIdDTO)}
        editEstimates={EditEstimate}
        isEditNotes={isEditNotes}
        onEstimatesDataUpdate={onEstimatesDataUpdate}
        EstimateData={EstimateData}
      />

      <CollectorModal
        isOpen={
          addCollectorModalOpen || EditCollectorOpen || isCollectorModalOpen
        }
        onClose={handleCloseCollectorModal}
        columns={collectorsColumns}
        allowedValues={allowedValues}
        site={siteData || ({} as SiteByIdDTO)}
        editCollector={EditCollector}
        isCollectorEditNotes={isCollectorEditNotes}
        onCollectorDataUpdate={onCollectorDataUpdate}
        collectorData={collectorData}
      />
      <DeleteDialog
        isOpen={deleteDialogControls.isOpen}
        onCancel={deleteDialogControls.close}
        onDeleteClick={
          toDeleteSite
            ? onDeleteClick
            : deleteCollectorId !== undefined
              ? () => deleteCollector(deleteCollectorId)
              : () => deleteEstimate(deleteEstimateId)
        }
        target={
          toDeleteSite ? (
            <SiteNameDetail name={siteData?.name} />
          ) : deleteCollectorId !== undefined ? (
            "Collector"
          ) : (
            "Parameter based Estimate"
          )
        }
        title={
          toDeleteSite
            ? "Delete Site?"
            : deleteCollectorId !== undefined
              ? "De-activate Utility Provider?"
              : "De-activate Parameter based estimate"
        }
      />
      <UserModal
        isOpen={addUserModalOpen}
        onClose={() => setAddUserModalOpen(false)}
        users={allowedUsers}
        site={siteData || ({} as SiteByIdDTO)}
      />
      {showSideInfoDrawer && (
        <UnitCostInfoDrawer
          unitCostData={UnitCostData}
          historicData={historicData}
          isOpen={showSideInfoDrawer}
          onClose={() => setShowSideInfoDrawer(false)}
        />
      )}
    </>
  );
};
