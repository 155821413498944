import React from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { COLORS } from 'components/consts';

// Styles to match the design
const useStyles = makeStyles({
  chip: {
    borderRadius: '6px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    marginRight: '4px',
  },
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      padding: '8.5px 14px',
      borderRadius: '8px',
      color: COLORS.Romance,
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.85rem',
    },
    '& .MuiChip-root': {
      height: 'auto',
    },
  },
});

interface Option {
  id: number;
  name: string;
}

interface MultiInputComponentProps {
  selectedValues: Option[] | undefined; 
  onChange: (newValue: Option[]) => void;
  onRemove: (removedOption: Option) => void; 
  placeholder?: string;
}

const MultiInputComponent: React.FC<MultiInputComponentProps> = ({
  selectedValues,
  onChange,
  onRemove,
  placeholder = 'Multi-Input',
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      options={[]}
      freeSolo
      value={selectedValues}
      onChange={(event, newValue) => {
        onChange(newValue as any);
      }}
      renderTags={(values: readonly Option[], getTagProps) => (
        values.map((option: Option, index: number) => {
          const { key, onDelete, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              variant="outlined"
              color="primary"
              label={option.name === undefined ? (option as any) : option.name}
              key={key}
              {...tagProps}
              onDelete={(event) => {
                onDelete(event);
                onRemove(option);  // Call `onRemove` with the ID of the removed chip
              }}
            />
          );
        })
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          className={classes.inputRoot}
        />
      )}
    />
  );
};

export default MultiInputComponent;